import { combineReducers } from 'redux';
import {
    PROFILE_PICTURE_SUCCESS,
    PROFILE_PICTURE_IN_PROGRESS,
    PROFILE_PICTURE_ERROR,
} from '../../constants/account';

const getProfilePicture = (state = {
    inProgress: false,
    value: null,
    error: null,
}, action) => {
    switch (action.type) {
    case PROFILE_PICTURE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROFILE_PICTURE_SUCCESS:
        return {
            inProgress: false,
            value: action.result,
            error: null,
        };
    case PROFILE_PICTURE_ERROR:
        return {
            ...state,
            inProgress: false,
            value: null,
            error: action.message,
        };
    default:
        return state;
    }
};

export default combineReducers({
    getProfilePicture,
});
