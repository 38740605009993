import {
    CONTRACTS_LIST_SEARCH_VALUE_SET,
    FETCH_CONTRACTS_LIST_ERROR,
    FETCH_CONTRACTS_LIST_IN_PROGRESS,
    FETCH_CONTRACTS_LIST_SUCCESS,
    FETCH_WHITELIST_CONTRACTS_LIST_ERROR,
    FETCH_WHITELIST_CONTRACTS_LIST_IN_PROGRESS,
    FETCH_WHITELIST_CONTRACTS_LIST_SUCCESS,
    FETCH_WHITELIST_CONTRACT_DETAILS_ERROR,
    FETCH_WHITELIST_CONTRACT_DETAILS_IN_PROGRESS,
    FETCH_WHITELIST_CONTRACT_DETAILS_SUCCESS,
} from '../../constants/contracts';
import { combineReducers } from 'redux';
import { DEFAULT_LIMIT, DEFAULT_SEARCH, DEFAULT_SKIP } from '../../config';
import createContract from './createContract';

const contractsList = (state = {
    list: [],
    inProgress: false,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case FETCH_CONTRACTS_LIST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CONTRACTS_LIST_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.value,
            skip: action.skip,
            limit: action.limit,
            search: action.search,
            total: action.total,
        };
    case FETCH_CONTRACTS_LIST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const contractsListSearch = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case CONTRACTS_LIST_SEARCH_VALUE_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const whitelistContractsList = (state = {
    list: [],
    inProgress: false,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case FETCH_WHITELIST_CONTRACTS_LIST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_WHITELIST_CONTRACTS_LIST_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                list: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                list: [...state.list, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case FETCH_WHITELIST_CONTRACTS_LIST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const whitelistContractDetails = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case FETCH_WHITELIST_CONTRACT_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_WHITELIST_CONTRACT_DETAILS_SUCCESS:
        return {
            ...state,
            value: action.value,
            inProgress: false,
        };
    case FETCH_WHITELIST_CONTRACT_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    contractsList,
    contractsListSearch,
    createContract,
    whitelistContractsList,
    whitelistContractDetails,
});
